// import jQuery from 'jquery';
// window.$ = jQuery; // workaround for https://github.com/parcel-bundler/parcel/issues/333

var jquery = require("jquery");
window.$ = window.jQuery = jquery;

import 'popper.js';
import 'bootstrap';
// import en from '../languages/en.json';
import bookCover from '../src/assets/book-cover.png';

import instantsearch from 'instantsearch.js/es';
import {
  searchBox,
  infiniteHits,
  configure,
  stats,
  analytics,
  refinementList,
  sortBy,
  currentRefinements,
  toggleRefinement
} from 'instantsearch.js/es/widgets';
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';
import {
  SearchClient as TypesenseSearchClient
} from 'typesense'; // To get the total number of docs

const images = {
  'amazon_icon': {
    'svg': '../public/images/amazon_icon.svg'
  },
  'archive_icon': {
    'svg': '../public/images/archive_icon.svg'
  },
  'generic_link_icon': {
    'svg': '../public/images/generic_link_icon.svg'
  },
  'opengraph_banner': {
    'png': '../public/images/opengraph_banner.png'
  }
};

// const languages = {
//   en: en,
//   // hindi

//   // we can add more languages here
// };

const STOP_WORDS = [
  "a", "am", "an", "and", "as", "at", "by", "c's", "co", "do", "de",
  "eg", "et", "for", "he", "hi", "i", "i'd", "i'm", "ie", "if", "in",
  "inc", "is", "it", "its", "me", "my", "nd", "no", "non", "nor", "not",
  "of", "off", "oh", "ok", "on", "or", "per", "que", "qv", "rd", "re", "so",
  "sub", "t's", "th", "the", "to", "too", "two", "un", "up", "us", "vs", "we"
];

// Source: https://stackoverflow.com/a/901144/123545
const anchorParams = new Proxy(new URLSearchParams(window.location.hash.replace('#', '')), {
  get: (anchorParams, prop) => anchorParams.get(prop),
});

let TYPESENSE_SERVER_CONFIG = {
  apiKey: process.env.TYPESENSE_SEARCH_ONLY_API_KEY, // Be sure to use an API key that only allows searches, in production
  nodes: [{
    host: anchorParams.host ? anchorParams.host : process.env.TYPESENSE_HOST,
    port: process.env.TYPESENSE_PORT,
    protocol: process.env.TYPESENSE_PROTOCOL,
  },],
  numRetries: 8,
  useServerSideSearchCache: false,
};

// [2, 3].forEach(i => {
//   if (process.env[`TYPESENSE_HOST_${i}`]) {
//     TYPESENSE_SERVER_CONFIG.nodes.push({
//       host: process.env[`TYPESENSE_HOST_${i}`],
//       port: process.env.TYPESENSE_PORT,
//       protocol: process.env.TYPESENSE_PROTOCOL,
//     });
//   }
// });

// Unfortunately, dynamic process.env keys don't work with parcel.js
// So need to enumerate each key one by one

if (process.env[`TYPESENSE_HOST_2`]) {
  TYPESENSE_SERVER_CONFIG.nodes.push({
    host: anchorParams.host ? anchorParams.host : process.env[`TYPESENSE_HOST_2`],
    port: process.env.TYPESENSE_PORT,
    protocol: process.env.TYPESENSE_PROTOCOL,
  });
}

if (process.env[`TYPESENSE_HOST_3`]) {
  TYPESENSE_SERVER_CONFIG.nodes.push({
    host: anchorParams.host ? anchorParams.host : process.env[`TYPESENSE_HOST_3`],
    port: process.env.TYPESENSE_PORT,
    protocol: process.env.TYPESENSE_PROTOCOL,
    protocol: process.env.TYPESENSE_PROTOCOL,
  });
}

if (process.env[`TYPESENSE_HOST_NEAREST`]) {
  TYPESENSE_SERVER_CONFIG['nearestNode'] = {
    host: anchorParams.host ? anchorParams.host : process.env[`TYPESENSE_HOST_NEAREST`],
    port: process.env.TYPESENSE_PORT,
    protocol: process.env.TYPESENSE_PROTOCOL,
  };
}

const INDEX_NAME = process.env.TYPESENSE_COLLECTION_NAME;

async function getIndexSize() {
  let typesenseSearchClient = new TypesenseSearchClient({
    ...TYPESENSE_SERVER_CONFIG,
    useServerSideSearchCache: true
  });
  let results = await typesenseSearchClient
    .collections(INDEX_NAME)
    .documents()
    .search({
      q: '*'
    });

  return results['found'];
}

let indexSize;

(async () => {
  indexSize = await getIndexSize();
})();

function iconForUrl(url) {
  if (url.includes('amazon.com')) {
    return images['amazon_icon']['svg'];
  } else if (url.includes('openlibrary')) {
    return images['archive_icon']['svg'];
  } else {
    return images['generic_link_icon']['svg'];
  }
}

function topMarginForUrl(url) {
  if (url.includes('amazon.com')) {
    return 1;
  } else {
    return 0;
  }
}

function urlsObjectsForBookObject(bookObject) {
  let urls = []

  if (bookObject['isbn']) {
    urls.push(`https://www.amazon.com/dp/${bookObject['isbn']}`)
  }

  urls.push(`https://openlibrary.org${bookObject['id']}`)

  return urls.map(u => {
    return {
      url: u,
      icon: iconForUrl(u),
      topMargin: topMarginForUrl(u)
    }
  })

  return urls
}

if (window.location.href.includes('anubadsha_prod') && window.location.href.includes('book')) {
  const bookID = window.location.href.split('-').pop();
  displayBookDetails(bookID);
}


function queryWithoutStopWords(query) {
  const words = query.replace(/[&\/\\#,+()$~%.':*?<>{}]/g, '').split(' ');
  return words
    .map(word => {
      if (STOP_WORDS.includes(word.toLowerCase())) {
        return null;
      } else {
        return word;
      }
    })
    .filter(w => w)
    .join(' ')
    .trim();
}

// function getLanguageFile(languageCode){
//   return languages[languageCode];
// }


// const userLanguage = 'en';
// const languageFile = getLanguageFile(userLanguage);

// console.log(languageFile) // testing

const titleCheckbox = document.getElementById('title-checkbox');
const translatorCheckbox = document.getElementById('translator-checkbox');
const publisherCheckbox = document.getElementById('publisher-checkbox');
const languageCheckbox = document.getElementById('language-checkbox');
const collectionCheckbox = document.getElementById('collection-checkbox');
const genreCheckbox = document.getElementById('genre-checkbox');
const origTitleCheckbox = document.getElementById('orig-title-checkbox');
const origPublisherCheckbox = document.getElementById('orig-publisher-checkbox');
const origAuthorCheckbox = document.getElementById('orig-author-checkbox');
const origLanguageCheckbox = document.getElementById('orig-language-checkbox');
const isbnCheckbox = document.getElementById('isbn-checkbox');
const issnCheckbox = document.getElementById('issn-checkbox');

titleCheckbox.addEventListener('change', updateQueryBy);
translatorCheckbox.addEventListener('change', updateQueryBy);
publisherCheckbox.addEventListener('change', updateQueryBy);
languageCheckbox.addEventListener('change', updateQueryBy);
collectionCheckbox.addEventListener('change', updateQueryBy);
genreCheckbox.addEventListener('change', updateQueryBy);
origTitleCheckbox.addEventListener('change', updateQueryBy);
origPublisherCheckbox.addEventListener('change', updateQueryBy);
origAuthorCheckbox.addEventListener('change', updateQueryBy);
origLanguageCheckbox.addEventListener('change', updateQueryBy);
isbnCheckbox.addEventListener('change', updateQueryBy);
issnCheckbox.addEventListener('change', updateQueryBy);

// title, author, translator, language, original language
let query_by = 'title,orig_authors,authors,language,orig_language,xdata'

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: TYPESENSE_SERVER_CONFIG,
  // The following parameters are directly passed to Typesense's search API endpoint.
  //  So you can pass any parameters supported by the search endpoint below.
  //  queryBy is required.
  additionalSearchParameters: {
    // queryBy: query_by,
    queryBy: query_by,
  },
});

// example query
// http://localhost:1234/?anubadsha_prod%5Bquery%5D=hindi&query_by=language
// /?anubadsha_prod%5Bquery%5D=women&query_by=language

// save infiniteHits to a variable

const infiniteHitsWidget = infiniteHits({
  container: '#hits',
  cssClasses: {
    list: 'list-unstyled grid-container',
    item: 'd-flex flex-column search-result-card bg-light-2 p-3',
    loadMore: 'btn btn-primary mx-auto d-block mt-4',
    disabledLoadMore: 'd-none',
  },
  // truncate data.title to 50 characters with a read more button that shows the rest of the title in that box
  // if we use thumbnail then we can use this in templates
  // <img class="mt-2 mb-3 img-fluid rounded" src="{{thumbnail}}" alt="{{ title }} by {{authors}}" height="200">

  templates: {
    item: `
    
          <h6 class="text-primary font-weight-light font-letter-spacing-loose mb-0">
          <a  data-book-id="{{id}}" class="no-underline text-primary font-serif" title="{{title}}" >
            {{title}}
            </a>
          </h6>
          <div class="text-muted truncate-text-authors ">
          Translator: <span class="clickable-search-term font-weight-bold" title="Search for more books by {{authors}}">{{authors}}</span>
          </div>
          <div class="text-muted truncate-text-orig-authors">
            Original Author: {{orig_authors}}
          </div>
          <div class="text-muted">
            Language: {{language}}
          </div>
          <div class="text-muted">
           Publisher: {{publisher}}
          </div>
          <div class="text-muted">
          Orig. Language: {{orig_language}}
          </div>
          <div class="text-muted">
          Genre: {{subjects}}
          </div>
          {{#orig_title}}
          <div class="text-muted">
          Orig. Title: {{orig_title}}
          </div>
          {{/orig_title}}
          <div class="mt-auto text-right">
            {{#urls}}
            <a class="ml-2" href="{{ url }}" target="_blank" ><img class="mt-{{topMargin}}" src="{{ icon }}" alt="{{ type }}" height="14"></a>
            {{/urls}}
          </div>
      `,
    empty: 'No books found for <q>{{ query }}</q>. Try another search term.',
  },
  transformItems: items => {
    return items.map(item => {
      // console.log(item.orig_authors, item.authors)
      return {
        ...item,
        urls: urlsObjectsForBookObject(item),
      };
    });
  },
})


function updateQueryBy() {

  const selectedFields = [];
  if (titleCheckbox.checked || window.location.search.includes('query_by=title')) {
    selectedFields.push('title');
    if (!titleCheckbox.checked && window.location.search.includes('query_by=title')) {
      titleCheckbox.checked = true;
    }
  }
  if (translatorCheckbox.checked || window.location.search.includes('query_by=authors')) {
    selectedFields.push('authors');
    if (!translatorCheckbox.checked && window.location.search.includes('query_by=authors')) {
      translatorCheckbox.checked = true;
    }
  }
  if (publisherCheckbox.checked || window.location.search.includes('query_by=publisher')) {
    selectedFields.push('publisher');
    if (window.location.search.includes('query_by=publisher')) {
      publisherCheckbox.checked = true;
    }
  }
  if (languageCheckbox.checked || window.location.search.includes('query_by=language')) {
    selectedFields.push('language');
    if (window.location.search.includes('query_by=language')) {
      languageCheckbox.checked = true;
    }
  }
  if (collectionCheckbox.checked || window.location.search.includes('query_by=collection')) {
    selectedFields.push('collection');
    if (window.location.search.includes('query_by=collection')) {
      collectionCheckbox.checked = true;
    }
  }
  if (genreCheckbox.checked || window.location.search.includes('query_by=subjects')) {
    selectedFields.push('subjects');
    if (window.location.search.includes('query_by=subjects')) {
      genreCheckbox.checked = true;
    }
  }
  if (origTitleCheckbox.checked || window.location.search.includes('query_by=orig_title')) {
    selectedFields.push('orig_title');
    if (window.location.search.includes('query_by=orig_title')) {
      origTitleCheckbox.checked = true;
    }
  }
  if (origPublisherCheckbox.checked || window.location.search.includes('query_by=orig_publisher')) {
    selectedFields.push('orig_publisher');
    if (window.location.search.includes('query_by=orig_publisher')) {
      origPublisherCheckbox.checked = true;
    }
  }
  if (origAuthorCheckbox.checked || window.location.search.includes('query_by=orig_authors')) {
    selectedFields.push('orig_authors');
    if (window.location.search.includes('query_by=orig_authors')) {
      origAuthorCheckbox.checked = true;
    }
  }
  if (origLanguageCheckbox.checked || window.location.search.includes('query_by=orig_language')) {
    selectedFields.push('orig_language');
    if (window.location.search.includes('query_by=orig_language')) {
      origLanguageCheckbox.checked = true;
    }
  }
  if (isbnCheckbox.checked || window.location.search.includes('query_by=isbn')) {
    selectedFields.push('isbn');
    if (window.location.search.includes('query_by=isbn')) {
      isbnCheckbox.checked = true;
    }
  }
  if (issnCheckbox.checked || window.location.search.includes('query_by=issn')) {
    selectedFields.push('issn');
    if (window.location.search.includes('query_by=issn')) {
      issnCheckbox.checked = true;
    }
  }
  if (query_by === '') {
    query_by = 'all';
  }

  query_by = `${selectedFields.join(',')}`;
  // console.log(selectedFields)

  typesenseInstantsearchAdapter.updateConfiguration({
    server: TYPESENSE_SERVER_CONFIG,
    additionalSearchParameters: {
      queryBy: query_by,
    },
  })

  // // add query_by to url
  // const url = new URL(window.location.href);
  // url.searchParams.set('query_by', query_by);
  // window.history.replaceState({}, '', url);


  search.helper.search();
  // reload widgets infiniteHits in dom
  search.addWidgets([
    infiniteHitsWidget,

  ])

}



const searchClient = typesenseInstantsearchAdapter.searchClient;

const search = instantsearch({
  searchClient,
  indexName: INDEX_NAME,
  routing: true,
  searchFunction(helper) {
    if (helper.state.query === '') {
      $('#results-section').addClass('d-none');
    } else {
      $('#results-section').removeClass('d-none');
      helper.search();
    }
  },
});


let debounceTimerId;

search.addWidgets([
  searchBox({
    container: '#searchbox',
    showSubmit: false,
    showReset: false,
    placeholder: 'Type in a book title or author',
    autofocus: true,
    cssClasses: {
      input: 'form-control',
    },
    queryHook(query, search) {
      const modifiedQuery = queryWithoutStopWords(query);
      if (modifiedQuery.trim() !== '' && modifiedQuery.trim().length > 2) {
        if (debounceTimerId) {
          clearTimeout(debounceTimerId);
        }
        debounceTimerId = setTimeout(() => search(modifiedQuery), 250);
      }
    },
  }),


  analytics({
    pushFunction(formattedParameters, state, results) {
      window.ga(
        'set',
        'page',
        (window.location.pathname + window.location.search).toLowerCase()
      );
      window.ga('send', 'pageView');
    },
  }),

  stats({
    container: '#stats',
    templates: {
      text: ({
        nbHits,
        hasNoResults,
        hasOneResult,
        processingTimeMS
      }) => {
        let statsText = '';
        if (hasNoResults) {
          statsText = 'No results';
        } else if (hasOneResult) {
          statsText = '1 result';
        } else {
          statsText = `✨ ${nbHits.toLocaleString()} results`;
        }
        return `${statsText} found ${indexSize ? ` - Searched ${indexSize.toLocaleString()} books` : ''
          } in ${processingTimeMS}ms.`;
      },
    },
  }),
  infiniteHitsWidget,



  // if all in one search filter is needed
  // refinementList({
  //   container: '#search-filter',
  //   attribute: 'collection',
  //   searchable: true,
  //   searchablePlaceholder: 'Search through filters',
  //   cssClasses: {
  //     searchableInput: 'form-control form-control-sm mb-2 border-light-2',
  //     searchableSubmit: 'd-none',
  //     searchableReset: 'd-none',
  //   },
  // }),


  refinementList({
    container: '#author-refinement-list',
    // translators
    attribute: 'authors',
    searchable: true,
    limit: 5,
    searchablePlaceholder: 'Search authors',
    showMore: true,
    showMoreLimit: 10,
    cssClasses: {
      searchableInput: 'd-none',
      searchableSubmit: 'd-none',
      searchableReset: 'd-none',
      showMore: 'btn btn-secondary btn-sm align-content-center',
      disabledShowMore: 'd-none',
      list: 'list-unstyled',
      count: 'badge badge-light bg-light-2 ml-2 text-primary my-1',
      label: 'd-flex align-items-center text-capitalize ',
      labelText: 'mx-2 truncate-text',
      checkbox: 'mr-2',
    }
  }),

  refinementList({
    container: '#language-refinement-list',
    attribute: 'language',
    searchable: true,
    limit: 5,
    searchablePlaceholder: 'Search languages',
    showMore: true,
    showMoreLimit: 10,
    cssClasses: {
      // searchableInput: 'form-control form-control-sm mb-2 border-light-2',
      searchableInput: 'd-none',
      searchableSubmit: 'd-none',
      searchableReset: 'd-none',
      showMore: 'btn btn-secondary btn-sm align-content-center',
      // showMore: 'd-none',
      disabledShowMore: 'd-none',
      list: 'list-unstyled',
      count: 'badge badge-light bg-light-2 ml-2 text-primary my-1',
      label: 'd-flex align-items-center text-capitalize',
      labelText: 'mx-2 truncate-text',
      checkbox: 'mr-2',
    }
  }),

  refinementList({
    container: '#publisher-refinement-list',
    attribute: 'publisher',
    searchable: true,
    limit: 5,
    searchablePlaceholder: 'Search publishers',
    showMore: true,
    showMoreLimit: 10,
    cssClasses: {
      // searchableInput: 'form-control form-control-sm mb-2 border-light-2',
      searchableInput: 'd-none',
      searchableSubmit: 'd-none',
      searchableReset: 'd-none',
      showMore: 'btn btn-secondary btn-sm align-content-center',
      // showMore: 'd-none',
      disabledShowMore: 'd-none',
      list: 'list-unstyled',
      count: 'badge badge-light bg-light-2 ml-2 text-primary my-1',
      label: 'd-flex align-items-center text-capitalize',
      labelText: 'mx-2 truncate-text',
      checkbox: 'mr-2',
    }
  }),

  refinementList({
    container: '#orig_language-refinement-list',
    attribute: 'orig_language',
    searchable: true,
    limit: 5,
    searchablePlaceholder: 'Search original languages',
    showMore: true,
    showMoreLimit: 10,
    cssClasses: {
      // searchableInput: 'form-control form-control-sm mb-2 border-light-2',
      searchableInput: 'd-none',
      searchableSubmit: 'd-none',
      searchableReset: 'd-none',
      showMore: 'btn btn-secondary btn-sm align-content-center',
      // showMore: 'd-none',
      disabledShowMore: 'd-none',
      list: 'list-unstyled',
      count: 'badge badge-light bg-light-2 ml-2 text-primary my-1',
      label: 'd-flex align-items-center text-capitalize',
      labelText: 'mx-2 truncate-text',
      checkbox: 'mr-2',
    }
  }),

  refinementList({
    container: '#orig_authors-refinement-list',
    // orig authors
    attribute: 'orig_authors',
    searchable: true,
    limit: 5,
    searchablePlaceholder: 'Search original authors',
    showMore: true,
    showMoreLimit: 10,
    cssClasses: {
      // searchableInput: 'form-control form-control-sm mb-2 border-light-2',
      searchableInput: 'd-none',
      searchableSubmit: 'd-none',
      searchableReset: 'd-none',
      showMore: 'btn btn-secondary btn-sm align-content-center',
      // showMore: 'd-none',
      disabledShowMore: 'd-none',
      list: 'list-unstyled',
      count: 'badge badge-light bg-light-2 ml-2 text-primary my-1',
      label: 'd-flex align-items-center text-capitalize',
      labelText: 'mx-2 truncate-text',
      checkbox: 'mr-2',
    }
  }),

  refinementList({
    container: '#subject-refinement-list',
    attribute: 'subjects',
    searchable: true,
    limit: 5,
    searchablePlaceholder: 'Search subjects',
    showMore: true,
    showMoreLimit: 10,
    cssClasses: {
      // searchableInput: 'form-control form-control-sm mb-2 border-light-2',
      searchableInput: 'd-none',
      searchableSubmit: 'd-none',
      searchableReset: 'd-none',
      showMore: 'btn btn-secondary btn-sm align-content-center',
      // showMore: 'd-none',
      disabledShowMore: 'd-none',
      list: 'list-unstyled',
      count: 'badge badge-light bg-light-2 ml-2 text-primary my-1',
      label: 'd-flex align-items-center text-capitalize',
      labelText: 'mx-2 truncate-text',
      checkbox: 'mr-2',
    },
  }),


  sortBy({
    container: '#sort-by',
    items: [{
      label: 'Recent first',
      value: `${INDEX_NAME}`
    },
    {
      label: 'Oldest first',
      value: `${INDEX_NAME}/sort/publish_date:asc`
    },
    ],
    cssClasses: {
      select: 'custom-select custom-select-sm',
    },
  }),
  configure({
    hitsPerPage: 15,
  }),
  currentRefinements({
    container: '#current-refinements',
    cssClasses: {
      list: 'list-unstyled',
      label: 'd-none',
      item: 'h5',
      category: 'badge badge-dark bg-dark mr-2 mb-2',
      categoryLabel: 'text-capitalize truncate-text',
      delete: 'ml-2 text-white bg-dark shadow-none border-0',
    },
    transformItems: items => {
      const modifiedItems = items.map(item => {
        return {
          ...item,
          label: '',
        };
      });
      return modifiedItems;
    },
  }),
]);

function handleSearchTermClick(event) {
  const $searchBox = $('#searchbox input[type=search]');
  search.helper.clearRefinements();
  $searchBox.val(event.currentTarget.textContent);
  search.helper.setQuery($searchBox.val()).search();
}

search.on('render', function () {
  // Make author names clickable
  $('#hits .clickable-search-term').on('click', handleSearchTermClick);

  // Read directions button
  $('.readDirectionsButton').on('click', event => {
    $(event.currentTarget).parent().siblings().first().modal('show');
  });
});

search.start();

$(function () {
  const $searchBox = $('#searchbox input[type=search]');
  // Set initial search term
  // if ($searchBox.val().trim() === '') {
  //   $searchBox.val('book');
  //   search.helper.setQuery($searchBox.val()).search();
  // }
  // Handle example search terms
  $('.clickable-search-term').on('click', handleSearchTermClick);

  // Clear refinements, when searching
  $searchBox.on('keydown', event => {
    search.helper.clearRefinements();
  });

  // $searchBox.on('change', () => {
  //   // document.getElementsByClassName('book-data')[0].style.display = 'hidden';
  //   $('.book-data').addClass('d-none');
  //   $('#results-section').removeClass('d-none');
  // })
  // if search box is empty then hide results section and book data
  $searchBox.on('change', () => {
    if ($searchBox.val().trim() === '') {
      $('.book-data').addClass('d-none');
      $('#results-section').addClass('d-none');
    }
  })

  search.on('render', function () {
    // if params contains book then display book details
    // if searchbox value changes then hide book data and show results section
    search.helper.on('change', function () {
      $('.book-data').addClass('d-none');
      const url = new URL(window.location.href);
      url.searchParams.delete('book');
      window.history.pushState({}, '', url);
      $('.book-data').html('');
      $('#results-section').removeClass('d-none');
    });
    if (window.location.href.includes('anubadsha_prod') && window.location.href.includes('book')) {
      const bookID = window.location.href.split('-').pop();
      displayBookDetails(bookID);
    }
    else {
      $('.book-data').addClass('d-none');
      $('#results-section').removeClass('d-none');
    }

  });

  $searchBox.on('change', function () {
    if ($searchBox.val().trim() !== '') {
      $('.book-data').addClass('d-none');
      $('.book-data').html('');
      $('#results-section').removeClass('d-none');
    }
  });




  if (!matchMedia('(min-width: 768px)').matches) {
    $searchBox.on('focus, keydown', () => {
      $('html, body').animate({
        scrollTop: $('#searchbox-container').offset().top,
      },
        500
      );
    });
  }
});





// advanced search
const advancedSearchBtn = document.getElementById('advanced-search-button');
const categoryBadges = document.getElementById('category-badges');
advancedSearchBtn.style.cursor = 'pointer';

advancedSearchBtn.addEventListener('click', () => {
  categoryBadges.classList.toggle('d-none');
  if (categoryBadges.classList.contains('d-none')) {
    advancedSearchBtn.textContent = 'Advanced Search';
  } else {
    advancedSearchBtn.textContent = 'Hide Advanced Search';
  }
});

// search based on query_by
if (window.location.search.includes('query_by')) {
  titleCheckbox.checked = false;
  translatorCheckbox.checked = false;
  languageCheckbox.checked = false;
  origAuthorCheckbox.checked = false;
  origLanguageCheckbox.checked = false;
  updateQueryBy();
  categoryBadges.classList.remove('d-none');
  advancedSearchBtn.textContent = 'Hide Advanced Search';
  // console.log(query_by)
}



// refine button for mobile
$(document).ready(function () {
  $('.navbar-toggler').on('click', function () {
    $('.navbar-collapse').toggleClass('collapse');
  });
});





// if (window.location.href.includes('anubadsha_prod') && window.location.href.includes('book')) {
//   document.getElementsByClassName('book-data')[0].style.display = 'hidden';
// }

// if href url has url.searchParams.set('book', `${book.title}-${bookID}`); then displaybookdetails(bookid)
// if (window.location.href.includes('anubadsha_prod') && window.location.href.includes('book')) {
//   const bookID = window.location.href.split('-').pop();
//   displayBookDetails(bookID);
// }

// getBookDataFromId

async function getBookDataFromId(id) {
  let typesenseSearchClient = new TypesenseSearchClient({
    ...TYPESENSE_SERVER_CONFIG,
    useServerSideSearchCache: true
  });
  let bookData = await typesenseSearchClient
    .collections(INDEX_NAME).documents().search({
      q: "*",
      filter_by: `id:=${id}`
    })
    .then(function (data) {
      return data['hits'][0].document;
    }).catch(function (error) {
      console.log(error);
    });
  // console.log(bookData)
  return bookData;
}


function displayBookDetails(bookID) {
  $('.book-data').removeClass('d-none');
  $('#results-section').addClass('d-none');
  getBookDataFromId(bookID).then(book => {
    const url = new URL(window.location.href);
    url.searchParams.set('anubadsha_prod[query]', '');
    url.searchParams.set('book', `${book.title}-${bookID}`);
    window.history.pushState({}, '', url);
    // if back button is clicked then hide book data and show results section
    window.onpopstate = function (event) {
      $('.book-data').addClass('d-none');
      $('.book-data').html('');
      $('#results-section').removeClass('d-none');
    };
    console.log(book)
    const bookData = document.querySelector(".book-data");
    bookData.innerHTML = `
    <div class="row  search-result-card bg-light-2 pt-3 pt-md-5 pb-2 pb-md-4 px-3 px-md-5 mt-3">
    <div class="col-md-4">
        <div class="thumbnail-img">
          <img class="mt-2 mb-3 img-fluid" src="${book.thumbnail || bookCover}" alt="${book.title} by ${book.authors}" >
        </div>
    </div>
    <div class="col-md-8 mt-4 mt-md-0">
      <div class="book-details">
        <h1 class="h2 font-weight-bold book-title text-primary clickable-search-term font-serif">
        ${book.title || "Title not available"}
        </h1>
        <h4 class="h6 text-muted original-author ${book.orig_authors ? "" : "d-none"}">
        Original Author(s):
        ${book.orig_authors ?
        book.orig_authors.map(subject => `<a class="sub-links" href="/?anubadsha_prod%5Bquery%5D=${subject}&query_by=orig_authors">${subject}</a>`).join(", ")
        : "not available"}
        </h4> 
        <h4 class="h6 text-muted book-author ${book.authors ? "" : "d-none"}">
          Translator(s):
            ${book.authors ?
        book.authors.map(subject => `<a class="sub-links" href="/?anubadsha_prod%5Bquery%5D=${subject}&query_by=authors">${subject}</a>`).join(", ")
        : "not available"}
        </h4>
        <h4 class="h6 text-muted language ${book.language ? "" : "d-none"}">
        Language: ${book.language ? `${book.language}` : "not available"}
        </h4>
        <h4 class="h6 text-muted language ${book.orig_language ? "" : "d-none"}">
        Orig. Language: ${book.orig_language ? `${book.orig_language}` : "not available"}
        </h4>
        <h4 class="h6 text-muted book-publisher ${book.publisher ? "" : "d-none"}">
        Publisher: ${book.publisher ? `<a class="sub-links" href="/?anubadsha_prod%5Bquery%5D=${book.publisher}&query_by=publisher">${book.publisher}</a>` : "not available"}
        </h4>
        <h4 class="h6 text-muted book-year ${book.publish_date.toString().slice(0, 4).length < 4 ? "d-none" : book.publish_date ? "" : "d-none"}">
        Published in:
            ${book.publish_date ? `${book.publish_date.toString().slice(0, 4)}` : " not available"}
        </h4>
        <h4 class="h6 text-muted book-isbn ${book.isbn ? "" : "d-none"}">
        ISBN: ${book.isbn ? `${book.isbn}` : "ISBN not available"}
        </h4>
        <h4 class="h6 text-muted book-subject ${book.subjects ? "" : "d-none"}">
        Genre:
            ${book.subjects ?
        book.subjects.map(subject => `<a class="sub-links" href="/?anubadsha_prod%5Bquery%5D=${subject}&query_by=subjects">${subject}</a>`).join(", ")
        : "Subject(s) not available"}
        </h4>
        <h4 Title: class="h6 text-muted orig-title ${book.orig_title ? "" : "d-none"}">
        Original Title: ${book.orig_title ? `<a class="sub-links" href="/?anubadsha_prod%5Bquery%5D=${book.orig_title}&query_by=orig_title">${book.orig_title}</a>` : "not available"}
        </h4>
        <h4 class="h6 text-muted orig-publisher ${book.orig_publisher === "N/A" ? "d-none" : book.orig_publisher ? "" : "d-none"}">
        Publisher of Original: ${book.orig_publisher ? `<a class="sub-links" href="/?anubadsha_prod%5Bquery%5D=${book.orig_publisher}&query_by=orig_publisher">${book.orig_publisher}</a>` : "not available"}
        </h4>
        <h4 class="h6 text-muted orig-year ${book.orig_publish_date ? "" : "d-none"}">
        Original Published in: ${book.orig_publish_date ? `${book.orig_publish_date.toString().slice(0, 4)}` : "not available"}
        </h4>
        <h4 class="h6 text-muted collection ${book.collection ? "" : "d-none"}">
              Part of: ${book.collection ? `<a class="sub-links" href="/?anubadsha_prod%5Bquery%5D=${book.collection}&query_by=collection">${book.collection}</a>` : "not available"}
        </h4>
        <h4 class="h6 text-muted book-issn ${book.issn ? "" : "d-none"}">
        ISSN: ${book.issn ? ` ${book.issn}` : "ISSN not available"}
        </h4>


        </div>
      </div>
    </div>
  </div>
    `;


    // const moreInfoBtn = document.querySelector(".more-info-btn");

    // moreInfoBtn.style.cursor = "pointer";
    // moreInfoBtn.addEventListener("click", () => {
    //   const moreInfo = document.querySelector(".more-info");
    //   moreInfo.classList.toggle("d-none");
    //   moreInfoBtn.innerHTML = moreInfo.classList.contains("d-none") ? "More info >>" : "Less info <<";
    // });


  });

}


document.getElementById('hits').addEventListener('click', function (event) {
  const target = event.target;

  // Check if the clicked element is an anchor tag with the specified class
  if (target.tagName === 'A' && target.classList.contains('no-underline')) {
    const bookId = target.dataset.bookId; // Assuming you set a data attribute for bookId
    displayBookDetails(bookId);
    // console.log(bookId);
  }
});
